import React from 'react';
import Seo from '../components/layout/Seo'
import Layout from '../components/layout/Layout';
import Section from '../components/layout/Section'
import PageHeader from '../components/PageHeader';
import MediaPlayer2 from '../components/MediaPlayer2';
// import recordingsData from '../data/recordings-data';
import fieldRecordingsData from '../data/field-data'
import { egyptLakeReleaseDate as firstReleaseDate } from '../data/releaseDate-data';

const RecordingsPage = () => {

  const now = new Date()

  return (

    <Layout>
      <Seo title="Field Recordings" description={`Listen to a collection of raw field recordings used in the composition of Höhn's debut, eponymous LP.`} />

      <Section sectionName="Field Recordsings">
                

            {now >= firstReleaseDate ? (
              <>
              <PageHeader 
                    pageTitle={`Field Recordings`}
                    pageSubtitle={`A collection of raw audio, recorded in conjuction with the album.`}
                    size={`text-4xl md:text-5xl -mb-3`}
                />
              <MediaPlayer2 tracks={fieldRecordingsData} />
              </>
              
            ) : (
              <PageHeader 
                    pageTitle={`Field Recordings`}
                    pageSubtitle={`Coming soon...`}
                    size={`text-4xl md:text-5xl -mb-3`}
                />
            )}

            
      </Section>


    </Layout>
  )
}

export default RecordingsPage;
