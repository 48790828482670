import { nanoid } from 'nanoid'
import ambientCreek from '../media/recordings/REC/AmbientCreek.mp3'
import bearLocker from '../media/recordings/REC/BearLocker.mp3'
import hiHatREC from '../media/recordings/REC/HiHat.mp3'
import kickREC from '../media/recordings/REC/Kick.mp3'
import hiHatOka from '../media/recordings/Oka/HiHat.mp3'
import keysOka from '../media/recordings/Oka/Keys.mp3'
import kickOka from '../media/recordings/Oka/Kick.mp3'
import snareOka from '../media/recordings/Oka/Snare.mp3'
import tapsOka from '../media/recordings/Oka/TapsandKidYelling.mp3'
import gasStoveCCH from '../media/recordings/CCH/GasStove.mp3'
import waterPumpCCH from '../media/recordings/CCH/WaterPump.mp3'
import backdoorKippCalgary from '../media/recordings/Calgary/BackdoorKipp.mp3'
import tiktokCalgary from '../media/recordings/Calgary/TikTok.mp3'
import bassLC from '../media/recordings/LaCrete/Bass.mp3'
import logLC from '../media/recordings/LaCrete/Log.mp3'
import scaryPitchHitsLC from '../media/recordings/LaCrete/ScaryPitchHits.mp3'
import scrunchyLC from '../media/recordings/LaCrete/Scrunchy.mp3'
import snareLeafLC from '../media/recordings/LaCrete/SnareLeaf.mp3'
import tripletLC from '../media/recordings/LaCrete/TripletScratch.mp3'
import lakeWindID9 from '../media/recordings/ID9/LakeWind.mp3'
import mosquitoID9 from '../media/recordings/ID9/MosquitoSnap.mp3'
import rockID9 from '../media/recordings/ID9/Rockeee.mp3'
import hungryEL from '../media/recordings/EgyptLake/GettingHungryNow.mp3'
import grassyEL from '../media/recordings/EgyptLake/GrassyDrops.mp3'
import kickEL from '../media/recordings/EgyptLake/Kick.mp3'
import lakeLapEL from '../media/recordings/EgyptLake/LakeLapping.mp3'
import rocksInLakeEL from '../media/recordings/EgyptLake/RocksInLake.mp3'
import { egyptLakeReleaseDate, redEarthCreekReleaseDate, hohnAlbumDate } from './releaseDate-data';

export default [
    {
        mainTrack: `Red Earth Creek`,
        id: nanoid(),
        date: redEarthCreekReleaseDate,
        trackNumber: 1,
        fieldRecordings: [
            {
                id: nanoid(),
                title: `Ambient Creek`,
                artist: `Höhn`,
                audioSrc: ambientCreek
            },
            {
                id: nanoid(),
                title: `Bear Locker`,
                artist: `Höhn`,
                audioSrc: bearLocker
            },
            {
                id: nanoid(),
                title: `HiHat`,
                artist: `Höhn`,
                audioSrc: hiHatREC
            },
            {
                id: nanoid(),
                title: `Kick`,
                artist: `Höhn`,
                audioSrc: kickREC
            }
        ]
    },
    {
        mainTrack: `Oka (feat. Stef Haynes)`,
        id: nanoid(),
        date: hohnAlbumDate,
        trackNumber: 2,
        fieldRecordings: [
            {
                id: nanoid(),
                title: `HiHat`,
                artist: `Höhn`,
                audioSrc: hiHatOka
            },
            {
                id: nanoid(),
                title: `Keys`,
                artist: `Höhn`,
                audioSrc: keysOka
            },
            {
                id: nanoid(),
                title: `Kick`,
                artist: `Höhn`,
                audioSrc: kickOka
            },
            {
                id: nanoid(),
                title: `Snare`,
                artist: `Höhn`,
                audioSrc: snareOka
            },
            {
                id: nanoid(),
                title: `Taps and Kid Yelling`,
                artist: `Höhn`,
                audioSrc: tapsOka
            }
        ]
    },
    {
        mainTrack: `Scarp`,
        id: nanoid(),
        date: hohnAlbumDate,
        trackNumber: 3,
        fieldRecordings: [
            {
                id: nanoid(),
                title: `Gas Stove`,
                artist: `Höhn`,
                audioSrc: gasStoveCCH
            },
            {
                id: nanoid(),
                title: `Water Pump`,
                artist: `Höhn`,
                audioSrc: waterPumpCCH
            }
        ]
    },
    {
        mainTrack: `Calgary`,
        id: nanoid(),
        date: hohnAlbumDate,
        trackNumber: 4,
        fieldRecordings: [
            {
                id: nanoid(),
                title: `Backdoor + Kipp`,
                artist: `Höhn`,
                audioSrc: backdoorKippCalgary
            },
            {
                id: nanoid(),
                title: `TikTok`,
                artist: `Höhn`,
                audioSrc: tiktokCalgary
            }
        ]
    },
    {
        mainTrack: `La Crete`,
        id: nanoid(),
        date: hohnAlbumDate,
        trackNumber: 5,
        fieldRecordings: [
            {
                id: nanoid(),
                title: `Bass`,
                artist: `Höhn`,
                audioSrc: bassLC
            },
            {
                id: nanoid(),
                title: `Log`,
                artist: `Höhn`,
                audioSrc: logLC
            },
            {
                id: nanoid(),
                title: `Scary Pitch Hits`,
                artist: `Höhn`,
                audioSrc: scaryPitchHitsLC
            },
            {
                id: nanoid(),
                title: `Scrunchy`,
                artist: `Höhn`,
                audioSrc: scrunchyLC
            },
            {
                id: nanoid(),
                title: `Snare Leaf`,
                artist: `Höhn`,
                audioSrc: snareLeafLC
            },
            {
                id: nanoid(),
                title: `Triplet Scratch`,
                artist: `Höhn`,
                audioSrc: tripletLC
            }
        ]
    },
    {
        mainTrack: `ID9`,
        id: nanoid(),
        date: hohnAlbumDate,
        trackNumber: 6,
        fieldRecordings: [
            {
                id: nanoid(),
                title: `Lake Wind`,
                artist: `Höhn`,
                audioSrc: lakeWindID9
            },
            {
                id: nanoid(),
                title: `Mosquito Snap`,
                artist: `Höhn`,
                audioSrc: mosquitoID9
            },
            {
                id: nanoid(),
                title: `Rock-eee`,
                artist: `Höhn`,
                audioSrc: rockID9
            }
        ]
    },
    {
        mainTrack: `Egypt Lake`,
        id: nanoid(),
        date: egyptLakeReleaseDate,
        trackNumber: 7,
        fieldRecordings: [
            {
                id: nanoid(),
                title: `Getting Hungry Now`,
                artist: `Höhn`,
                audioSrc: hungryEL
            },
            {
                id: nanoid(),
                title: `Grassy Drops`,
                artist: `Höhn`,
                audioSrc: grassyEL
            },
            {
                id: nanoid(),
                title: `Kick`,
                artist: `Höhn`,
                audioSrc: kickEL
            },
            {
                id: nanoid(),
                title: `Lake Lapping`,
                artist: `Höhn`,
                audioSrc: lakeLapEL
            },
            {
                id: nanoid(),
                title: `Rocks in Lake`,
                artist: `Höhn`,
                audioSrc: rocksInLakeEL
            }
        ]
    }
]

/*
export default [{
    REC: [
        {
            id: nanoid(),
            title: `Ambient Creek`,
            artist: `Höhn`,
            audioSrc: ambientCreek
        },
        {
            id: nanoid(),
            title: `Bear Locker`,
            artist: `Höhn`,
            audioSrc: bearLocker
        },
        {
            id: nanoid(),
            title: `HiHat`,
            artist: `Höhn`,
            audioSrc: hiHatREC
        },
        {
            id: nanoid(),
            title: `Kick`,
            artist: `Höhn`,
            audioSrc: kickREC
        }
    ],
    Oka: [
        {
            id: nanoid(),
            title: `HiHat`,
            artist: `Höhn`,
            audioSrc: hiHatOka
        },
        {
            id: nanoid(),
            title: `Keys`,
            artist: `Höhn`,
            audioSrc: keysOka
        },
        {
            id: nanoid(),
            title: `Kick`,
            artist: `Höhn`,
            audioSrc: kickOka
        },
        {
            id: nanoid(),
            title: `Snare`,
            artist: `Höhn`,
            audioSrc: snareOka
        },
        {
            id: nanoid(),
            title: `Taps and Kid Yelling`,
            artist: `Höhn`,
            audioSrc: tapsOka
        }
    ],
    CCH: [
        {
            id: nanoid(),
            title: `Gas Stove`,
            artist: `Höhn`,
            audioSrc: gasStoveCCH
        },
        {
            id: nanoid(),
            title: `Water Pump`,
            artist: `Höhn`,
            audioSrc: waterPumpCCH
        }
    ]
}]
*/