import React, { useState, useEffect, useRef } from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/src/styles.scss'
import '../css/player.scss'
import { tracklist, trackName, tracklistAlt, trackActive, masterList, mainItem, mainTitle, mediaPlayerWrapper, fixed, pinned } from '../css/recordings.module.scss'
import { useMediaQuery } from 'react-responsive'

export default function MediaPlayer2( {tracks, playOnLoad} ) {
    const [trackIndex, setTrackIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(0);

    const playerStyle = {
        backgroundColor: 'var(--color-background)',
        borderTop: '1px solid var(--color-black)'
    }

    

    const initialTrack = () => {
        setTrackIndex(tracks[0].fieldRecordings[0].audioSrc);
        setIsPlaying(tracks[0].fieldRecordings[0].id);
      };

      useEffect(() => {

        playOnLoad === true ? (
            initialTrack()
        ) : (
            null
        )
        
      }, []);

      const songRef = useRef()

      const handleClick = e => {
        if (songRef?.current?.contains(e.target)) {
          // inside click
          return;
        } else if (! songRef?.current?.contains(e.target) && e.target.tagName === 'BUTTON') {
             {
                setIsPlaying(false);
            }
        }
        // outside click
        
      };

      useEffect(() => {
        document.addEventListener("mousedown", handleClick);
 
        return () => {
          document.removeEventListener("mousedown", handleClick);
        };
      }, []);

    //   const [footerHeight, setFooterHeight] = useState(null)
      
      const aboveSm = useMediaQuery({ query: '(min-width: 640px)' })

      let stickyOffset = 430;
      if (aboveSm) stickyOffset = 340
      
      const [isVisible, setIsVisible] = useState(true);

      const listenToScroll = () => {
        // const footer = document.querySelector('footer')
        // setFooterHeight(footer.clientHeight)

        let heightToHideFrom = document.body.offsetHeight - stickyOffset
        const winScroll = window.innerHeight + window.scrollY
           
        if (winScroll > heightToHideFrom) { 
           isVisible &&      // to limit setting state only the first time         
             setIsVisible(false);
        } else {
             setIsVisible(true);
        }  
      };

    //   const listenToScroll = () => {
    //     let heightToHideFrom = document.body.offsetHeight - stickyOffset
    //     const winScroll = window.innerHeight + window.scrollY
           
    //     if (winScroll > heightToHideFrom) { 
    //        isVisible &&      // to limit setting state only the first time         
    //          setIsVisible(false);
    //     } else {
    //          setIsVisible(true);
    //     }  
    //   };

      useEffect(() => {   
        window.addEventListener("scroll", listenToScroll);
        return () => 
           window.removeEventListener("scroll", listenToScroll); 
      }, [stickyOffset]) // on window resize, it'll now re-render the effect
    
    const now = new Date()
    

    return (

        <div className={`space-y-14 relative ${masterList}`}>

            {tracks
            .filter(a => now >= a.date)
            .map(primarySong => {

            return (
            <div key={primarySong.id} className={mainItem}>

                <h4 className={`text-2xl mb-3 font-medium ${mainTitle}`} data-number={primarySong.trackNumber}>{primarySong.mainTrack}</h4>
                { primarySong.fieldRecordings.length && 

                <ol role={`list`} className={`m-0 p-0 space-y-5 sm:columns-2 sm:gap-x-7 md:columns-2 md:gap-x-10 sm:space-y-0 sm:-mb-6 ${[tracklist, tracklistAlt].join(" ")}`}>

                    {
                      
                    primarySong.fieldRecordings.map(song => {

                    let trackClassName = trackName;
                    if (isPlaying === song.id) trackClassName = [trackName, trackActive].join(" ");

                    

                        return (

                        <li className={`inline-block w-full break-inside-avoid sm:pb-6`} key={song.id}>
                            <div className={trackClassName}>
                                <button 
                                    onClick={() => {
                                        setTrackIndex(song.audioSrc);
                                        setIsPlaying(song.id);
                                    }}
                                    ref={songRef}
                                    className={`text-base ${isPlaying === song.id ? 'pointer-events-none' : 'pointer-events-auto'}`}
                                    >
                                    { isPlaying === song.id ? (
                                        <strong className={`font-secondary uppercase font-semibold text-base tracking-[0.025rem]`}>{song.title}</strong>
                                    ) : (
                                        song.title
                                    ) }
                                </button>
                            </div>
                        </li>

                        )
                    })
                    }
                </ol>
                }
                
            </div>
            )
            })}

            <div className={`${mediaPlayerWrapper} ${isVisible ? fixed : pinned}`}>
                <AudioPlayer
                    src={trackIndex}
                    layout={`stacked`}
                    showJumpControls={false}
                    style={playerStyle}
                    className={`field-media-player fixed-version`}
                    // other props here
                />
            </div>
        </div>
    )
}

MediaPlayer2.defaultProps = {
    playOnLoad: false
}